.center{
  margin:0px auto;
}
.mv{
  margin-top:25px;
  margin-bottom:25px
}

.singleColumn {
  margin-bottom: 25px;
  margin-top:25px;
  width: 100%;
  padding: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  // background-color: red;
  .innerText {
    max-width: 960px;
    font-size: 16px;
  }
}

.button {
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  transition: all 0.5s;
  :hover {
    opacity: 0.8;
    transition: all 0.5s;
  }
}

.columnContainer {
  max-width: 960px;
  display: flex;
  flex-direction: row;

  .thirdColumn {
    flex: 1;
    height: 100%;
    padding: 10px;
    align-items: center;

    .imageCol {
      width: 100%;
      height: 25vw;
      object-fit: cover;
      margin-bottom: 20px;
    }
  }
}

.singleColumnImage {
  min-height: 200px;
  background-size: cover;
  margin:0px auto;
  margin-top:50px;
  margin-bottom:50px;
  max-width: 960px;
  margin-bottom: 25px;
  background-position: center center;
}


.textInput {
  height:100%; 
  width:100%; 
  border: none; 
  font-family: "Montserrat"
}